.leftContainer {
  height: 100%;
  min-width: 300px;
  max-width: 15%;
  text-align: left;
  /* background: #343951; */
  background-color: rgb(255, 255, 255);

  /* color: #d0d6db; */
  color: #000 !important;
  cursor: default;
  /* transition-duration: 0.3s; */
  /* overflow: hidden; */

  transition: all 300ms ease-in-out;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
}

.left-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  padding: 0 5%;
}

.nav-items {
  width: 212px;
  margin: 0 auto;
  margin-top: 4rem;
}

.leftPane {
  width: 100%;
  max-width: 320px;
  /* position: absolute; */
  height: 80%;
  text-align: left;
  /* border: 1px solid black; */
  /* background: #343951; */
  background-color: rgb(255, 255, 255);
  /* padding-left: 2%; */
  /* color: #d0d6db; */
  padding-left: 1rem;
  padding-right: 1rem;

  padding-top: 2rem;
  color: black;
  cursor: default;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* display: flex;
  flex-direction: column;
  justify-content: space-around; */
  transition: all 0.3s ease-in-out;
  /* overflow: hidden; */
}
.leftPane_folders {
  max-height: 150px;
  /* height: 150px; */
  overflow: scroll;
  /* background-color: green; */
  white-space: nowrap;
  max-width: 300px;
  overflow: scroll;
  text-overflow: ellipsis;
  /* overflow-x: hidden; */
  -ms-overflow-style: none;
}
.leftPane_folders::-webkit-scrollbar {
  display: none;
}
/* .leftPane_folders::-webkit-scrollbar {
  width: 10px;


}
.leftPane_folders::-webkit-scrollbar-track {
  background: #3f51b5;
  border-radius: 5px;
  margin-right: 2%;
}
.leftPane_folders::-webkit-scrollbar-thumb {
  background: #05e395;
  border-radius: 5px;
  margin-right: 2%;
}
.leftPane_folders::-webkit-scrollbar-thumb:hover {
  background: #0f0;
} */
.leftPane_short {
  width: 5%;
  height: 100%;
  /* text-align: center;
  align-items: center; */
  /* background: #343951; */
  background-color: white;
  padding-left: 0.8%;
  /* color: #d0d6db; */
  color: black;
  cursor: default;
}
.sarvvid_logo {
  width: 80%;
}
.sarvvid_logo_short {
  width: 60px;
  margin-top: 50px;
}
.leftPane_buttons {
  font-size: 18px;

  /* background: blue; */
}
.leftPane_buttons_button {
  margin: 1.5rem 0rem;
  opacity: 0.5;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.leftPane_buttons_button h6 {
  margin-top: 10px;
  margin-left: 1rem;
  color: rgb(29, 29, 29);
}

.leftPane_buttons_button_short {
  margin: 180%;
  opacity: 0.8;
}
.leftPane_buttons_button:hover {
  opacity: 1;
}

.leftPane_new {
  /* position: absolute; */
  /* bottom: 25%; */
  /* position: absolute; */
  /* bottom: 20%; */
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.leftPane_new_short {
  /* position: absolute; */
  bottom: 20%;
  /* position: relative;
  top: 5%; */
}
.home_link {
  box-sizing: border-box;
  text-decoration: none !important;
  color: rgb(29, 29, 29) !important;
  opacity: 0.8;

  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.leftPane_buttons_button:hover {
  padding-left: 5px;
}

.home_link h6 {
  margin-top: 10px;
  margin-left: 1rem;
  color: rgb(29, 29, 29);
}

.home_link:hover {
  opacity: 1;
}
.FileRequest_modal {
  overflow: scroll;
  /* border-radius: 2%; */
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.FileRequest_modal::-webkit-scrollbar {
  display: none;
}
.div_FileRequest_heading {
  height: min-content;
  margin-bottom: 2%;
  cursor: default;
}
.FileRequestHeading {
  color: #00b3ff;
  /* color: #3c4043; */
}
.FileRequestBody {
  text-align: left;
  color: #3c4043;
  letter-spacing: 0.02em;
}
.FileRequestInstruction {
  font-size: 135%;
  margin-bottom: 4%;
}
.FileHash_text {
  width: 100% !important;
}
.FileRequest_button {
  background: #00b3ff;
  outline: none;
  margin-top: 2%;
  border: 1px solid #dadce0;
  width: 20%;
  padding-top: 1%;
  padding-bottom: 1%;
  border-radius: 4px;
  font-size: 120%;
  transition: all 0.3s ease-in-out;
  /* letter-spacing: 0.0107142857em; */
  letter-spacing: 0.02em;
  color: black;
  /* position: absolute; */
  right: 4%;
}
.FileRequest_button:hover {
  cursor: pointer;
  /* background-color: #f6fafe; */
  /* color: white; */
}
/* .upgrade_plan_button:disabled {
  pointer-events: none;
  cursor: default;
  color: #3c4043;
} */
.FileRequest_button:focus {
  outline: none;
}

.logout-btn {
  display: flex;
  align-items: center;
  justify-content: center;

  margin: auto;
  opacity: 0.36;
  cursor: pointer;

  transition: all 300ms ease-in-out;
}

.logout-btn:hover {
  opacity: 1;
}

.close-toggle-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logout-btn h3 {
  margin: 0;
  margin-left: 1rem;
  font-size: 1.3rem;
}

.logout-section {
  width: 80%;
  margin: 0 auto;
  border-top: 2px solid #c4c4c4;
  padding: 2rem;
}

.leftContainer.dark-theme {
  background: #0d0d0d;
}

.leftContainer.dark-theme .leftPane {
  background: #0d0d0d;
}

.leftContainer.dark-theme .leftPane_buttons {
  color: #fff;
}

.leftContainer.dark-theme .leftPane_buttons_button h6 {
  color: #fff;
}

.leftContainer.dark-theme .logout-btn h3 {
  color: #fff;
}

.banner {
  background: -webkit-linear-gradient(45deg, #085078 10%, #85d8ce 90%);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 auto;

  animation: slide 5s infinite;
}

@keyframes slide {
  0% {
    transform: translateY(-10%);
  }
  50% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-10%);
  }
}

.banner p {
  margin: 0;
  font-size: 1.2rem;
}

@media (min-width: 1000px) {
  .close-toggle-btn {
    display: none;
  }
}

@media (max-width: 1000px) {
  .leftContainer {
    transform: translateX(-100%);
  }

  .leftContainer.opened {
    transform: translateX(0%);
  }

  .left-header {
    justify-content: space-between;
    padding: 0 2%;
  }

  .leftPane_new {
    display: none;
  }

  .leftPane {
    padding-top: 0rem;
  }
}
