.nav{
    background: white;
    width: 100%;
    padding: 1rem 5%;
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.25);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

}

.navcontainer{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    
    

}

.options{
    display: flex;
    align-items: center;
}

.option{
    margin: 0 1rem;
    cursor: pointer;
    padding:4px 5px;
    border-radius: 12px;
    color: #4fd1c5;
    transition: all 300ms ease-in-out;
    
}

.active,
.option:hover
{
    background: #4fd1c5;
    color: white;
}