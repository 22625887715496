.midPane_cards {
  width: 100%;
  background: #fff;
  /* height: 100%; */

  flex-direction: row;
  height: 60vh;
  overflow-x: scroll;

  /* max-width: 57%; */
  flex-wrap: wrap;
  padding-top: 1rem;
  transition: all 300ms ease-in-out;
}

.midPane_cards::-webkit-scrollbar {
  display: none;
}

.midPane_card {
  width: 10%;
  min-width: 10%;
  max-width: 10%;
  color: #005288;
  padding-bottom: 1%;
  background-color: #b2d6f8;
  margin-left: 1%;
  border-radius: 10px;
  margin-bottom: 2.5%;
  cursor: default;
  display: flex;
  align-items: center;
  background: green;
}
.midPane_card:hover {
  position: relative;
  box-shadow: 0 0 20px rgb(0, 195, 255);
}

.midPane_cards.dark-theme {
  background-color: #121212;
}
