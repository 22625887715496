@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-color: #00b3ff;
  --secondary-color: #05e395;
  --tertiary-color: black;
  /* --font-type: Poppins;
  --default-font-color: #2e266f; */
}
.App {
  text-align: center;
  height: 100vh;
  align-items: center;
  /* background-color: var(--tertiary-color); */
  width: 100vw;
  background-color: #fafafa;
  /* background-color: var(--secondary-color); */
}

.hv-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Dashboard {
  height: 100%;
  width: 100%;
  background-color: #fafafa;

  display: flex;
  flex-direction: row;
  overflow: hidden;
  transition: all 300ms ease-in-out;
}

.Dashboard.dark{
  background: #121212;
}
.login h1 {
  /* Create Account */

  width: 100%;
  height: 100%;

  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 46px;
  line-height: 84px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  /* sarvvidblack */
  color: #000000;
}

.container {
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
}

.login {
  background: #fafafa;
  min-height: 100vh;
  width: 100%;

  /* padding-top: 8rem; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.main {
  width: 95%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loginbuttons {
  flex-direction: row;
}

.forms {
  display: flex;
  width: 595px;
  height: 600px;
  margin-bottom: 50px;
  padding: 1rem 3rem;
  background: white;
  box-shadow: 0px 4px 60px #e2ecf9;
  border-radius: 45px;
  overflow: hidden;
}

.form-main {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  transition: all 300ms ease-in-out;
}

.form-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-main.active1 {
  -webkit-transform: translateY(-850px);
          transform: translateY(-850px);
}

.nav {
  width: 100%;
  display: flex;
  align-items: center;
  box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.25);
  background: white;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.card {
  background: white;
  width: 50%;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8rem;
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  flex-direction: column-reverse;
}

.hidden {
  display: none !important;
}

.splash {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.steps {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 1rem;
}

.steps p {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  padding: 1rem 0;
}

.steps p span {
  font-weight: bold;
  margin: 0 0.2rem;
}

.help {
  color: #11acff;
}

.login form {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* background: green; */

  height: 450px;
}

.form {
  /* background-color: #FFFFFF; */
  background: transparent;
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  padding: 0 0px;
  width: 100%;
  text-align: center;
  /* height: 450px; */
}

.qrform {
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 0px;
  width: 100%;
  text-align: center;
}

.login input {
  background-color: #c1f4ff;

  padding: 6px 15px;
  margin: 8px 0;
  width: 100%;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  /* border-width: 2px;
  border-style: solid;
  border-color: #c4c4c4; */
  border: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.login input:focus {
  outline: none;
}

.login p {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  margin: 0px;
  margin-top: 5px;
  text-align: left;
  color: #000000;
}

.forgotpassheader {
  display: flex;
  justify-content: center;
  align-items: center !important;
  text-align: center !important;
  margin-top: 10px !important;
  width: 100% !important;
}

.login li {
  width: 100%;
  height: 25.73px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.03em;
  color: #999999;
  margin-top: 3px;
}

.center {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.center > * {
  margin: 15px 5px;
}

.login button {
  width: 200px;
  height: 58px;
  background: #000000;
  border-radius: 10px;
  border: 0px solid #ff4b2b;
  color: #ffffff;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  transition: -webkit-transform 80ms ease-in-out;
  transition: transform 80ms ease-in-out;
  transition: transform 80ms ease-in-out, -webkit-transform 80ms ease-in-out;
  margin-top: 15px;
  cursor: pointer;
}

.btn-md {
  width: 100px;
  height: 58px;
  background: #000000;
  border-radius: 10px;
  border: 0px solid #ff4b2b;
  color: #ffffff;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  transition: -webkit-transform 80ms ease-in-out;
  transition: transform 80ms ease-in-out;
  transition: transform 80ms ease-in-out, -webkit-transform 80ms ease-in-out;
  cursor: pointer;
}

.login button:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}

.login button:focus {
  outline: none;
}

.login h3 {
  width: 100%;
  height: 25.73px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.03em;
  color: #999999;
  margin-top: 3px;
}

.login h3 span {
  color: #ff4b2b;
  cursor: pointer;
}

.login h3 span:hover {
  color: #ff3714;
}

.forgot-modal {
  width: 50%;

  margin: auto;
  margin-top: 10rem;
  background: #fff;
  padding: 2rem;
  border-radius: 1rem;
}

.country-select {
  margin-top: 7px;
}

.number-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.select-sec {
  width: 40%;
}

@media (max-width: 600px) {
  .main {
    display: flex;
    flex-direction: column;
    padding-top: 9rem;
    padding-bottom: 4rem;
  }

  .login button {
    width: 100px;
  }

  .forms {
    width: 90%;
  }

  .login h1 {
    /* Create Account */
    width: 100%;
    height: 100%;

    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 52px;
    /* identical to box height */

    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    /* sarvvidblack */

    color: #000000;
  }

  .login p {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
}

/* .login_QR {
  background-color: #fff;
  background-color: white;
  background: none;
  align-self: center;
  width: 50%;
  min-height: 90%;
  padding: 2%;
  display: flex;
  align-items: flex-start;
  border-radius: 5%;
  text-align: left;
  padding-top: 4%;
  z-index: 4002;
}

.login_QR_close {
  margin-left: 4%;
  color: #fff;
  position: relative;
  top: -8%;
  left: 1.5%;
  display: flex;

  align-self: auto;
  right: 2%;
}
.login_QR_close:hover {
  background-color: black;
  color: black;
} */


.login_QR{
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 50%;
  border-radius: 15px;
  min-height: 175px;

}

.login_QR_instruction {
  /* margin-right: 4%; */
  font-size: 20px;
  text-align: left;

}

.login_QR_instruction h3{
  font-size: 1.5rem;
}

.login_QR_instruction li{
  font-size: 1.3rem;
  margin-bottom: 15px;
}

@media (max-width:1400px){
  .login_QR{
    width: 90%;
    margin-bottom: 0rem;
  }
}

@media (max-width:1000px){
  .login_QR{
    display: flex;
    flex-direction: column-reverse;
    padding: 1rem;
  }
}
.nav{
    background: white;
    width: 100%;
    padding: 1rem 5%;
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 0px 6px 25px rgba(0, 0, 0, 0.25);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

}

.navcontainer{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    
    

}

.options{
    display: flex;
    align-items: center;
}

.option{
    margin: 0 1rem;
    cursor: pointer;
    padding:4px 5px;
    border-radius: 12px;
    color: #4fd1c5;
    transition: all 300ms ease-in-out;
    
}

.active,
.option:hover
{
    background: #4fd1c5;
    color: white;
}
.loginText {
    color: #007bff;
    font-weight: bold;
    cursor: pointer;
}

.successMessage {
    color: green;
    font-weight: bold;
}
.alertMessage {
    min-width: 200px;
    justify-content: space-between;
}
.createNewModal {
  position: fixed;
  bottom: 5%;
  
  
  color: #121212;
  /* color: white; */
  min-width: 30%;
  margin: auto;
  background-color: #eee;
  
  border-radius: 10px;
  overflow: scroll;
  /* padding-top: 15%; */
  box-shadow: 0 1px 10px rgb(60 64 67 / 30%);
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.createNewModal::-webkit-scrollbar {
  display: none;
}
.uploadingDiv {
  padding: 1rem 2rem;
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.uploadingFileNameDiv {
  width: 70%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.button-container {
  background: linear-gradient(#31ffad, #10adfe);
  width: 206px;
  padding: 3px;
  border-radius: 100px;

  margin: auto;
}

.logout-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hash_modal_inner {
  width: 50%;
  padding: 2rem;
  border-radius: 25px;
  background: #fff;
}

.hash_modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hash_modal_inner h1 {
  font-size: 1.7rem;
  text-align: center;
}

.hash_modal_inner h4 {
  font-size: 1.3rem;
  text-align: center;
}

.file_hash {
  opacity: 0.8;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 100ms ease-in-out;
}

.file_hash p {
  font-size: 1.3rem;
  margin: 0;
  margin-right: 1rem;
}

.file_hash:hover {
  opacity: 1;
}

.leftContainer {
  height: 100%;
  min-width: 300px;
  max-width: 15%;
  text-align: left;
  /* background: #343951; */
  background-color: rgb(255, 255, 255);

  /* color: #d0d6db; */
  color: #000 !important;
  cursor: default;
  /* transition-duration: 0.3s; */
  /* overflow: hidden; */

  transition: all 300ms ease-in-out;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
}

.left-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  padding: 0 5%;
}

.nav-items {
  width: 212px;
  margin: 0 auto;
  margin-top: 4rem;
}

.leftPane {
  width: 100%;
  max-width: 320px;
  /* position: absolute; */
  height: 80%;
  text-align: left;
  /* border: 1px solid black; */
  /* background: #343951; */
  background-color: rgb(255, 255, 255);
  /* padding-left: 2%; */
  /* color: #d0d6db; */
  padding-left: 1rem;
  padding-right: 1rem;

  padding-top: 2rem;
  color: black;
  cursor: default;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* display: flex;
  flex-direction: column;
  justify-content: space-around; */
  transition: all 0.3s ease-in-out;
  /* overflow: hidden; */
}
.leftPane_folders {
  max-height: 150px;
  /* height: 150px; */
  overflow: scroll;
  /* background-color: green; */
  white-space: nowrap;
  max-width: 300px;
  overflow: scroll;
  text-overflow: ellipsis;
  /* overflow-x: hidden; */
  -ms-overflow-style: none;
}
.leftPane_folders::-webkit-scrollbar {
  display: none;
}
/* .leftPane_folders::-webkit-scrollbar {
  width: 10px;


}
.leftPane_folders::-webkit-scrollbar-track {
  background: #3f51b5;
  border-radius: 5px;
  margin-right: 2%;
}
.leftPane_folders::-webkit-scrollbar-thumb {
  background: #05e395;
  border-radius: 5px;
  margin-right: 2%;
}
.leftPane_folders::-webkit-scrollbar-thumb:hover {
  background: #0f0;
} */
.leftPane_short {
  width: 5%;
  height: 100%;
  /* text-align: center;
  align-items: center; */
  /* background: #343951; */
  background-color: white;
  padding-left: 0.8%;
  /* color: #d0d6db; */
  color: black;
  cursor: default;
}
.sarvvid_logo {
  width: 80%;
}
.sarvvid_logo_short {
  width: 60px;
  margin-top: 50px;
}
.leftPane_buttons {
  font-size: 18px;

  /* background: blue; */
}
.leftPane_buttons_button {
  margin: 1.5rem 0rem;
  opacity: 0.5;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.leftPane_buttons_button h6 {
  margin-top: 10px;
  margin-left: 1rem;
  color: rgb(29, 29, 29);
}

.leftPane_buttons_button_short {
  margin: 180%;
  opacity: 0.8;
}
.leftPane_buttons_button:hover {
  opacity: 1;
}

.leftPane_new {
  /* position: absolute; */
  /* bottom: 25%; */
  /* position: absolute; */
  /* bottom: 20%; */
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.leftPane_new_short {
  /* position: absolute; */
  bottom: 20%;
  /* position: relative;
  top: 5%; */
}
.home_link {
  box-sizing: border-box;
  text-decoration: none !important;
  color: rgb(29, 29, 29) !important;
  opacity: 0.8;

  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.leftPane_buttons_button:hover {
  padding-left: 5px;
}

.home_link h6 {
  margin-top: 10px;
  margin-left: 1rem;
  color: rgb(29, 29, 29);
}

.home_link:hover {
  opacity: 1;
}
.FileRequest_modal {
  overflow: scroll;
  /* border-radius: 2%; */
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.FileRequest_modal::-webkit-scrollbar {
  display: none;
}
.div_FileRequest_heading {
  height: -webkit-min-content;
  height: min-content;
  margin-bottom: 2%;
  cursor: default;
}
.FileRequestHeading {
  color: #00b3ff;
  /* color: #3c4043; */
}
.FileRequestBody {
  text-align: left;
  color: #3c4043;
  letter-spacing: 0.02em;
}
.FileRequestInstruction {
  font-size: 135%;
  margin-bottom: 4%;
}
.FileHash_text {
  width: 100% !important;
}
.FileRequest_button {
  background: #00b3ff;
  outline: none;
  margin-top: 2%;
  border: 1px solid #dadce0;
  width: 20%;
  padding-top: 1%;
  padding-bottom: 1%;
  border-radius: 4px;
  font-size: 120%;
  transition: all 0.3s ease-in-out;
  /* letter-spacing: 0.0107142857em; */
  letter-spacing: 0.02em;
  color: black;
  /* position: absolute; */
  right: 4%;
}
.FileRequest_button:hover {
  cursor: pointer;
  /* background-color: #f6fafe; */
  /* color: white; */
}
/* .upgrade_plan_button:disabled {
  pointer-events: none;
  cursor: default;
  color: #3c4043;
} */
.FileRequest_button:focus {
  outline: none;
}

.logout-btn {
  display: flex;
  align-items: center;
  justify-content: center;

  margin: auto;
  opacity: 0.36;
  cursor: pointer;

  transition: all 300ms ease-in-out;
}

.logout-btn:hover {
  opacity: 1;
}

.close-toggle-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logout-btn h3 {
  margin: 0;
  margin-left: 1rem;
  font-size: 1.3rem;
}

.logout-section {
  width: 80%;
  margin: 0 auto;
  border-top: 2px solid #c4c4c4;
  padding: 2rem;
}

.leftContainer.dark-theme {
  background: #0d0d0d;
}

.leftContainer.dark-theme .leftPane {
  background: #0d0d0d;
}

.leftContainer.dark-theme .leftPane_buttons {
  color: #fff;
}

.leftContainer.dark-theme .leftPane_buttons_button h6 {
  color: #fff;
}

.leftContainer.dark-theme .logout-btn h3 {
  color: #fff;
}

.banner {
  background: -webkit-linear-gradient(45deg, #085078 10%, #85d8ce 90%);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 auto;

  -webkit-animation: slide 5s infinite;

          animation: slide 5s infinite;
}

@-webkit-keyframes slide {
  0% {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
  }
  50% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  100% {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
  }
}

@keyframes slide {
  0% {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
  }
  50% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  100% {
    -webkit-transform: translateY(-10%);
            transform: translateY(-10%);
  }
}

.banner p {
  margin: 0;
  font-size: 1.2rem;
}

@media (min-width: 1000px) {
  .close-toggle-btn {
    display: none;
  }
}

@media (max-width: 1000px) {
  .leftContainer {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }

  .leftContainer.opened {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }

  .left-header {
    justify-content: space-between;
    padding: 0 2%;
  }

  .leftPane_new {
    display: none;
  }

  .leftPane {
    padding-top: 0rem;
  }
}

.middlePane {
  background-color: #fafafa;
  /* background-color: var(--secondary-color); */
  width: 80%;

  height: 100%;
  /* border: 1px solid red; */
  /* font-size: ; */
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  transition: all 300ms ease-in-out;
}

.middlePane_searchBar {
  width: 90%;
  transition: all 300ms ease-in-out;
}

.middlePane_searchBar.dark-theme .searchBar_text {
  background: #0d0d0d;
}

.middlePane_upper {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.middlePane::-webkit-scrollbar {
  display: none;
}

.searchBar_text {
  width: 100% !important;
  /* background-color: green; */
  border-radius: 9px;
  outline: none;
  border: none;
  background-color: #fff;
  padding: 0.4rem 0.5rem;
  padding-left: 2rem;
  transition: all 300ms ease-in-out;
}

.searchBar_text.opened {
  padding-left: 1.5rem;
}

.searchBar_text.dark {
  color: #fff;
}

.middlePane_cards {
  /* height: 30%; */
  margin-top: 4%;
  transition: all 300ms ease-in-out;
  background: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5);
  border-radius: 30px;
  padding: 2rem;
  transition: all 300ms ease-in-out;

  /* margin-bottom: 1%; */
  /* border: 1px solid red; */
}

.recent_heading {
  padding-right: 2%;
  padding-left: 2%;
  color: #005288;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: default;
}

.recent_content {
  padding: 2%;
  color: #005288;
  cursor: default;
}
.file_content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.95%;
  border-bottom: 1px solid #005288;
  margin-bottom: 2%;
}
.file_content:hover {
  box-shadow: 0 0 20px rgb(0, 195, 255);
}

.close_button {
  display: none;
  opacity: 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.close_button.active_close {
  display: block;
  opacity: 1;
}

/* Theme button */

.theme-toggle {
  height: 24px;
  overflow: hidden;
}

.theme-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 300ms ease-in-out;
}

.theme-toggle.dark .theme-btn {
  -webkit-transform: translateY(-24px);
          transform: translateY(-24px);
}

.midPane-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navigation-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.table-header {
  width: 100%;
  display: flex;
  flex: 1 1;
}

.table-header p {
  font-size: 14px;
  color: #acacac;
  text-align: left;
  margin-left: 1rem;
}

.table-header p:nth-child(1) {
  flex: 0.7 1;
}

.table-header p:nth-child(2) {
  flex: 0.15 1;
}

.table-header p:nth-child(3) {
  flex: 0.15 1;
}

.middlePane.dark-theme {
  background: #121212;
}

.middlePane.dark-theme .navigation-container h2 {
  color: #fff;
}

.middlePane.dark-theme .middlePane_cards {
  box-shadow: 0px 10px 60px rgba(1, 36, 158, 0.5);
}

/* meida */

@media (min-width: 1000px) {
  .middlePane {
    padding-left: 344px;
    padding-top: 30px;
    padding-bottom: 55px;
    padding-right: 39px;
  }

  .min_upload_section {
    display: none;
  }
}

@media (max-width: 1000px) {
  .middlePane {
    width: 100%;
    padding: 0 1rem;
  }

  .theme-toggle {
    display: none;
  }

  .middlePane_searchBar {
    width: 100%;
  }

  .midPane-header {
    width: 100%;
  }
  .midPane-header h2 {
    font-size: 1.2rem;
  }

  .middlePane_cards {
    padding: 0.7rem;
    border-radius: 10px;
  }

  .table-header p:nth-child(2) {
    flex: 0.15 1;
    text-align: right;
  }

  .table-header p:nth-child(3) {
    flex: 0.15 1;
    text-align: right;
  }

  .min_upload_section {
    margin: 1rem 0;
  }
}

.midPane_cards {
  width: 100%;
  background: #fff;
  /* height: 100%; */

  flex-direction: row;
  height: 60vh;
  overflow-x: scroll;

  /* max-width: 57%; */
  flex-wrap: wrap;
  padding-top: 1rem;
  transition: all 300ms ease-in-out;
}

.midPane_cards::-webkit-scrollbar {
  display: none;
}

.midPane_card {
  width: 10%;
  min-width: 10%;
  max-width: 10%;
  color: #005288;
  padding-bottom: 1%;
  background-color: #b2d6f8;
  margin-left: 1%;
  border-radius: 10px;
  margin-bottom: 2.5%;
  cursor: default;
  display: flex;
  align-items: center;
  background: green;
}
.midPane_card:hover {
  position: relative;
  box-shadow: 0 0 20px rgb(0, 195, 255);
}

.midPane_cards.dark-theme {
  background-color: #121212;
}

.card-container{
    width: 100%;
    height: -webkit-min-content;
    height: min-content;
    color: #005288;
    padding: .8rem 0;
    padding-left: .8rem;
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: .5rem;
    display:flex;
    align-items:center;
    cursor: default;
    position: relative;
    transition: all 300ms ease-in-out;
}

.card-container:hover{
    background-color: rgb(238, 238, 238);
}

.name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-left:1rem
}

.file-card{
    width: 100%;
    display: flex;
    align-items: center;
    flex: 1 1;
}

.file-name{
    flex: 0.7 1;
    text-align: left;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding-right: 1rem;
    -webkit-touch-callout: none; 
    -webkit-user-select: none; 
        -ms-user-select: none; 
            user-select: none;
    transition: all 300ms ease-in-out;

}

.file-size{
    flex:0.15 1;
    text-align: left;
    margin: 0;
    transition: all 300ms ease-in-out;

}

.file-size p{
    margin: 0;
}

.file-type{
    flex:0.15 1;
    text-align: left;
    margin: 0;
    transition: all 300ms ease-in-out;

    
}

.file-type p{
    margin: 0;
}

.card-container.dark-theme{
    background: #121212;
}

.card-container.dark-theme:hover{
    background: #202020;
}

.card-container.dark-theme .file-name,
.card-container.dark-theme .file-size,
.card-container.dark-theme .file-type
{
    color: #fff;
}

.lottie{
    position: absolute;
    bottom: 0rem;
    right: 0rem;
}

@media(max-width:1000px){
    .name{
        margin-left: .5rem;
    }

    .file-size{
        font-size: .8rem;
        overflow: hidden;
        white-space: nowrap;
    }

    .file-type{
        font-size: .8rem;
        margin-left: .5rem;
        text-align: right;
    }

    .file-name{
        font-size: .8rem;
    }

    .card-container{
        padding-left: 0;
    }
}
.nav-container {
  /* background: green; */
  width: 100%;
}

.path-container {
  width: 60%;
  margin-left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.path {
  box-sizing: border-box;
  font-family: "Proxima Nova", sans-serif;
  font-size: 18px;
  color: #acacac;
  font-weight: lighter;
  word-spacing: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: rtl;
  -webkit-transform: translateX(-10px);
          transform: translateX(-10px);
}

.path .currentPath {
  color: #001800;
}

/* .back-btn{
    background: #FAFAFA;
    
    border: 1px solid #FAFAFA;
    box-sizing: border-box;
    box-shadow: 0px 100px 80px rgba(41, 72, 152, 0.05), 0px 64.8148px 46.8519px rgba(41, 72, 152, 0.037963), 0px 38.5185px 25.4815px rgba(41, 72, 152, 0.0303704), 0px 20px 13px rgba(41, 72, 152, 0.025), 0px 8.14815px 6.51852px rgba(41, 72, 152, 0.0196296), 0px 1.85185px 3.14815px rgba(41, 72, 152, 0.012037);
    border-radius: 10px;
} */

.nav-container.dark-theme .back-btn {
  box-shadow: 0;
  border: none;
  background: transparent;
}

/* Media */
@media screen and (max-width: 1000px) {
  .path-container {
    width: 100%;
  }
}

.menu-btn{
   
   cursor: pointer;
   transition: all 300ms ease-in-out;
}



.search-section{
    display: flex;
    align-items: center;
    width: 100%;

}

.search-section img {
    -webkit-transform: translateX(160%);
            transform: translateX(160%);
    z-index: 10;
}

.mobile_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.min_theme_toggle{
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 300ms ease-in-out;
}

.min-theme-toggle{
    height: 24px;
    overflow: hidden;
}

.min-theme-toggle.dark .min_theme_toggle{
    -webkit-transform: translateY(-24px);
            transform: translateY(-24px);
}

@media (min-width:1000px) {
    .menu-btn{
        display: none;
    }
    .mobile_header{
        display: none;
    }
}
.loader_container{
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .25);
    z-index: 99999;
}

.inner{
    width: 50%;
    height: 50%;
    border-radius: 50px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.boxes {
    height: 32px;
    width: 32px;
    position: relative;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    margin-top: 32px;
    -webkit-transform: rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px);
    transform: rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px);
}
.boxes .box {
    width: 32px;
    height: 32px;
    top: 0px;
    left: 0;
    position: absolute;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}



.boxes .box:nth-child(1) {
    -webkit-transform: translate(100%, 0);
    transform: translate(100%, 0);
    -webkit-animation: box1 1s linear infinite;
    animation: box1 1s linear infinite;
}
.boxes .box:nth-child(2) {
    -webkit-transform: translate(0, 100%);
    transform: translate(0, 100%);
    -webkit-animation: box2 1s linear infinite;
    animation: box2 1s linear infinite;
}
.boxes .box:nth-child(3) {
    -webkit-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
    -webkit-animation: box3 1s linear infinite;
    animation: box3 1s linear infinite;
}
.boxes .box:nth-child(4) {
    -webkit-transform: translate(200%, 0);
    transform: translate(200%, 0);
    -webkit-animation: box4 1s linear infinite;
    animation: box4 1s linear infinite;
}



.boxes .box > div {
    background: #5C8DF6;
    --translateZ: 15.5px;
    --rotateY: 0deg;
    --rotateX: 0deg;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #5C8DF6;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    -webkit-transform: rotateY(var(--rotateY)) rotateX(var(--rotateX)) translateZ(var(--translateZ));
    transform: rotateY(var(--rotateY)) rotateX(var(--rotateX)) translateZ(var(--translateZ));
}

.boxes .box > div:nth-child(1) {
    top: 0;
    left: 0;
    background: #5C8DF6;
}
.boxes .box > div:nth-child(2) {
    background: #145af2;
    right: 0;
    --rotateY: 90deg;
}
.boxes .box > div:nth-child(3) {
    background: #447cf5;
    --rotateX: -90deg;
}
.boxes .box > div:nth-child(4) {
    background: #DBE3F4;
    top: 0;
    left: 0;
    --translateZ: -90px;
}





@-webkit-keyframes box1 {
    0%,
    50% {
        -webkit-transform: translate(100%, 0);
                transform: translate(100%, 0);
    }
    100% {
        -webkit-transform: translate(200%, 0);
                transform: translate(200%, 0);
    }
}





@keyframes box1 {
    0%,
    50% {
        -webkit-transform: translate(100%, 0);
                transform: translate(100%, 0);
    }
    100% {
        -webkit-transform: translate(200%, 0);
                transform: translate(200%, 0);
    }
}

@-webkit-keyframes box2 {
    0%{
        -webkit-transform: translate(0, 100%);
                transform: translate(0, 100%);
    }
    50% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }
    100% {
        -webkit-transform: translate(100%, 0);
                transform: translate(100%, 0);
    }
}

@keyframes box2 {
    0%{
        -webkit-transform: translate(0, 100%);
                transform: translate(0, 100%);
    }
    50% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }
    100% {
        -webkit-transform: translate(100%, 0);
                transform: translate(100%, 0);
    }
}

@-webkit-keyframes box3 {
    0%,
    50% {
        -webkit-transform: translate(100%, 100%);
                transform: translate(100%, 100%);
    }
    100% {
        -webkit-transform: translate(0, 100%);
                transform: translate(0, 100%);
    }
}

@keyframes box3 {
    0%,
    50% {
        -webkit-transform: translate(100%, 100%);
                transform: translate(100%, 100%);
    }
    100% {
        -webkit-transform: translate(0, 100%);
                transform: translate(0, 100%);
    }
}

@-webkit-keyframes box4 {
    0%{
        -webkit-transform: translate(200%, 0);
                transform: translate(200%, 0);
    }
    50% {
        -webkit-transform: translate(200%, 100%);
                transform: translate(200%, 100%);
    }
    100% {
        -webkit-transform: translate(100%, 100%);
                transform: translate(100%, 100%);
    }
}

@keyframes box4 {
    0%{
        -webkit-transform: translate(200%, 0);
                transform: translate(200%, 0);
    }
    50% {
        -webkit-transform: translate(200%, 100%);
                transform: translate(200%, 100%);
    }
    100% {
        -webkit-transform: translate(100%, 100%);
                transform: translate(100%, 100%);
    }
}
.middlePane {
  background-color: #fafafa;
  /* background-color: var(--secondary-color); */
  width: 80%;

  height: 100%;
  /* border: 1px solid red; */
  /* font-size: ; */
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;

  transition: all 300ms ease-in-out;
}

.middlePane_searchBar {
  width: 90%;
  transition: all 300ms ease-in-out;
}

.middlePane_searchBar.dark-theme .searchBar_text {
  background: #0d0d0d;
}

.middlePane_upper {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.middlePane::-webkit-scrollbar {
  display: none;
}

.searchBar_text {
  width: 100% !important;
  /* background-color: green; */
  border-radius: 9px;
  outline: none;
  border: none;
  background-color: #fff;
  padding: 0.4rem 0.5rem;
  padding-left: 2rem;
  transition: all 300ms ease-in-out;
}

.searchBar_text.opened {
  padding-left: 1.5rem;
}

.searchBar_text.dark {
  color: #fff;
}

.middlePane_cards_recycle {
  /* height: 30%; */
  margin-top: 4%;
  transition: all 300ms ease;
  background: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5);
  border-radius: 30px;
  padding: 2rem;
  transition: all 300ms ease-in-out;

  /* margin-bottom: 1%; */
  /* border: 1px solid red; */
}

.recent_heading {
  padding-right: 2%;
  padding-left: 2%;
  color: #005288;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: default;
}

.recent_content {
  padding: 2%;
  color: #005288;
  cursor: default;
}
.file_content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.95%;
  border-bottom: 1px solid #005288;
  margin-bottom: 2%;
}
.file_content:hover {
  box-shadow: 0 0 20px rgb(0, 195, 255);
}

.close_button {
  display: none;
  opacity: 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.close_button.active_close {
  display: block;
  opacity: 1;
}

/* Theme button */

.theme-toggle {
  height: 24px;
  overflow: hidden;
}

.theme-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 300ms ease-in-out;
}

.theme-toggle.dark .theme-btn {
  -webkit-transform: translateY(-24px);
          transform: translateY(-24px);
}

.midPane-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navigation-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.table-header {
  width: 100%;
  display: flex;
  flex: 1 1;
}

.table-header p {
  font-size: 14px;
  color: #acacac;
  text-align: left;
  margin-left: 1rem;
}

.table-header p:nth-child(1) {
  flex: 0.7 1;
}

.table-header p:nth-child(2) {
  flex: 0.15 1;
}

.table-header p:nth-child(3) {
  flex: 0.15 1;
}

.middlePane.dark-theme {
  background: #121212;
}

.middlePane.dark-theme .navigation-container h2 {
  color: #fff;
}

.middlePane.dark-theme .middlePane_cards {
  box-shadow: 0px 10px 60px rgba(1, 36, 158, 0.5);
}

.empty_files_shared p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin: 1rem 0;
  text-align: center;
  letter-spacing: -0.01em;

  /* sarvvidgrey */

  color: #999999;
}

.empty_files_recycle p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin: 1rem 0;
  text-align: center;
  letter-spacing: -0.01em;

  /* sarvvidgrey */

  color: #999999;
}

.empty_files_shared_btn {
  width: 30%;
  background: #000;
  border: none;
  outline: none;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  font-size: 0.8rem;
  font-weight: normal;
  font-family: "Poppins";
  border-radius: 10px;
  cursor: pointer;
  text-transform: none;
  margin: 0;
  padding: 1.2rem 1rem;
  transition: all 0.3s ease-in-out;
}

.button_depth {
  padding: 0.8rem;
  background: #fafafa;
  border: 1px solid #fafafa;
  box-sizing: border-box;
  box-shadow: 0px 100px 80px rgba(41, 72, 152, 0.05),
    0px 64.8148px 46.8519px rgba(41, 72, 152, 0.037963),
    0px 38.5185px 25.4815px rgba(41, 72, 152, 0.0303704),
    0px 20px 13px rgba(41, 72, 152, 0.025),
    0px 8.14815px 6.51852px rgba(41, 72, 152, 0.0196296),
    0px 1.85185px 3.14815px rgba(41, 72, 152, 0.012037);
  border-radius: 10px;
}

.navigation-subcontainer {
  width: 100%;
  display: flex;
  margin: 1rem 0;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.middlePane_cards_shared {
  border-radius: 30px;
  margin-top: 5rem;
  box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5);
}

.middlePane_cards_shared.dark {
  box-shadow: 0px 10px 60px rgba(1, 36, 158, 0.5);
}

.empty_files_shared img {
  width: 80%;
  max-width: 500px;
}

.empty_files_recycle img {
  width: 80%;
  max-width: 300px;
}

.empty_files_shared {
  padding: 1rem;
}

.empty_files_recycle {
  border-radius: 30px;
  margin-top: 5rem;
  box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5);
  padding: 1rem;
}

.empty_files_recycle.dark {
  box-shadow: 0px 10px 60px rgba(1, 36, 158, 0.5);
}

/* meida */

@media (min-width: 1000px) {
  .middlePane {
    padding-left: 344px;
    padding-top: 30px;
    padding-bottom: 55px;
    padding-right: 39px;
  }

  .min_upload_section {
    display: none;
  }
}

@media (max-width: 1000px) {
  .middlePane {
    width: 100%;
    padding: 0 1rem;
  }

  .theme-toggle {
    display: none;
  }

  .middlePane_searchBar {
    width: 100%;
  }

  .midPane-header {
    width: 100%;
  }
  .midPane-header h2 {
    font-size: 1.2rem;
  }

  .middlePane_cards {
    padding: 0.7rem;
    border-radius: 10px;
  }

  .table-header p:nth-child(2) {
    flex: 0.15 1;
    text-align: right;
  }

  .table-header p:nth-child(3) {
    flex: 0.15 1;
    text-align: right;
  }

  .min_upload_section {
    margin: 1rem 0;
  }
}

.middlePane {
  background-color: #fafafa;
  /* background-color: var(--secondary-color); */
  width: 80%;

  height: 100%;
  /* border: 1px solid red; */
  /* font-size: ; */
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;  
  transition:all 300ms ease-in-out;
}


.middlePane_searchBar {
  
  width: 90%;
  transition: all 300ms ease-in-out;

}

.middlePane_searchBar.dark-theme .searchBar_text{
  background: #0d0d0d;
}


.middlePane_upper {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.middlePane::-webkit-scrollbar {
  display: none;
}

.searchBar_text {
  width: 100% !important;
  /* background-color: green; */
  border-radius: 9px;
  outline: none;
  border: none;
  background-color: #fff;
  padding: .4rem .5rem;
  padding-left: 2rem;
  transition: all 300ms ease-in-out;
}

.searchBar_text.opened{
  padding-left: 1.5rem;
}

.searchBar_text.dark{
  color: #fff;
  background: #0d0d0d;
}



.middlePane_cards {
  /* height: 30%; */
  margin-top: 4%;
  transition: all 300ms ease-in-out;
  background: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5);
  border-radius: 30px;
  padding: 2rem;
  transition: all 300ms ease-in-out;


  
  /* margin-bottom: 1%; */
  /* border: 1px solid red; */
  
}


.recent_heading {
  padding-right: 2%;
  padding-left: 2%;
  color: #005288;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: default;
}

.recent_content {
  padding: 2%;
  color: #005288;
  cursor: default;
}
.file_content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.95%;
  border-bottom: 1px solid #005288;
  margin-bottom: 2%;
}
.file_content:hover {
  box-shadow: 0 0 20px rgb(0, 195, 255);
}


.close_button{
  
  display: none;
  opacity: 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out ;
}

.close_button.active_close{
  display: block;
  opacity: 1;
}

.middlePane_cards_request{
  border-radius: 30px;
  margin-top: 5rem;
  box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5);
  padding: 1rem;
}

.middlePane_cards_request.dark{
  box-shadow: 0px 10px 60px rgba(1, 36, 158, 0.5);

}

.middlePane_cards_request.dark h3{
  color: #fff;
}



.requestFiles_content p{
  margin-top: 2rem;
}

.requestFiles img{
  width: 40%;
  max-width: 200px;
}
/* Theme button */

.theme-toggle{

  height: 24px;
  overflow: hidden;

}

.theme-btn{
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 300ms ease-in-out;
}

.theme-toggle.dark .theme-btn{
  -webkit-transform: translateY(-24px);
          transform: translateY(-24px);
}

.midPane-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navigation-container{
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}



.table-header{
  width: 100%;
  display: flex;
  flex: 1 1;
}

.table-header p{
  font-size: 14px;
  color: #acacac;
  text-align: left;
  margin-left: 1rem;
}

.table-header p:nth-child(1){
  flex: 0.7 1;
}

.table-header p:nth-child(2){
  flex: 0.15 1;
}

.table-header p:nth-child(3){
  flex: 0.15 1;
}

.middlePane.dark-theme{
  background: #121212;
}

.middlePane.dark-theme .navigation-container h2{
  color: #fff;
}

.middlePane.dark-theme .middlePane_cards{
  box-shadow: 0px 10px 60px rgba(1, 36, 158, 0.5);
}

.requestFiles {
  position: relative;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.requestFiles {
  position: relative;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  align-content: center;
  align-items: center;
  justify-content: center;
}

/* .rainbow_shadow {
  position: relative;
  top: 0;
  left: 0;
   align-content: center; 
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 69px;
  height: 103px;
} 
*/
/* .user_avatar {
  position: relative;
  display: flex;
  top: 0;
  left: 0;
  align-content: center;
  align-items: center;
  justify-content: center;
} */

.requestFilesMascot1 {
  position: relative;
  top: 0;
  left: 150px;  /* align-content: center; */
  align-content: center;
  align-items: center;
  justify-content: center;
 
}
.requestFilesMascot2 {
  position: relative;
  top: 0;
  left: -150px;  /* align-content: center; */
  align-content: center;
  align-items: center;
  justify-content: center;
 
}
.requestFiles_content {
  align-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  display: flex;
  width: 100%;
  /* height: 100%; */
  
}


.requestFiles h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: -0.01em;

  /* sarvvidblack */

  color: #000000;
}



.requestFiles p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.01em;
  margin: 0;

  /* sarvvidgrey */

  color: #999999;
}

.requestFiles_btn {
  width: 30%;
  background: #000;
  border: none;
  outline: none;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  font-size: 0.8rem;
  font-weight: normal;
  font-family: "Poppins";
  border-radius: 10px;
  cursor: pointer;
  text-transform: none;
  margin: 0;
  padding: 1.2rem 1rem;
  transition: all 0.3s ease-in-out;
  margin-top: 2rem;
}

.requestFiles_content h3{
  margin-bottom: 2rem;
}

.requestFiles_content p{
  margin-top: 2rem;
}


/* meida */

@media (min-width:1000px) {
  .middlePane{
    padding-left: 344px;
    padding-top: 30px;
    padding-bottom: 55px;
    padding-right: 39px;
    
  }

  .min_upload_section{
    display: none;
  }
}

@media(max-width:1000px){
  .middlePane{
    width: 100%;
    padding: 0 1rem;
  }

  .theme-toggle{
    display: none;
  }

  .middlePane_searchBar{
    width: 100%;
  }

  .midPane-header{
    width: 100%;
  }
  .midPane-header h2{
    font-size: 1.2rem;
  }

  .middlePane_cards{
    padding: .7rem;
    border-radius: 10px;
  }

  .table-header p:nth-child(2){
    flex: 0.15 1;
    text-align: right;
  }
  
  .table-header p:nth-child(3){
    flex: 0.15 1;
    text-align: right;
  }

  .min_upload_section{
    margin: 1rem 0;
  }

}


.middlePane {
  background-color: #fafafa;
  /* background-color: var(--secondary-color); */
  width: 80%;

  height: 100%;
  /* border: 1px solid red; */
  /* font-size: ; */
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  transition: all 300ms ease-in-out;
}

.middlePane_searchBar {
  width: 90%;
  transition: all 300ms ease-in-out;
}

.middlePane_searchBar.dark-theme .searchBar_text {
  background: #0d0d0d;
}

.middlePane_upper {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 300ms ease-in-out;
}

.middlePane::-webkit-scrollbar {
  display: none;
}

.searchBar_text {
  width: 100% !important;
  /* background-color: green; */
  border-radius: 9px;
  outline: none;
  border: none;
  background-color: #fff;
  padding: 0.4rem 0.5rem;
  padding-left: 2rem;
  transition: all 300ms ease-in-out;
}

.searchBar_text.opened {
  padding-left: 1.5rem;
}

.searchBar_text.dark {
  color: #fff;
}

.middlePane_cards {
  /* height: 30%; */
  margin-top: 4%;
  transition: all 300ms ease-in-out;
  background: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5);
  border-radius: 30px;
  padding: 2rem;
  transition: all 300ms ease-in-out;

  /* margin-bottom: 1%; */
  /* border: 1px solid red; */
}

.recent_heading {
  padding-right: 2%;
  padding-left: 2%;
  color: #005288;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: default;
  transition: all 300ms ease-in-out;
}

.recent_content {
  padding: 2%;
  color: #005288;
  cursor: default;
  transition: all 300ms ease-in-out;
}
.file_content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.95%;
  border-bottom: 1px solid #005288;
  margin-bottom: 2%;
  transition: all 300ms ease-in-out;
}
.file_content:hover {
  box-shadow: 0 0 20px rgb(0, 195, 255);
}

.close_button {
  display: none;
  opacity: 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.close_button.active_close {
  display: block;
  opacity: 1;
}

/* Theme button */

.theme-toggle {
  height: 24px;
  overflow: hidden;
}

.theme-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 300ms ease-in-out;
}

.theme-toggle.dark .theme-btn {
  -webkit-transform: translateY(-24px);
          transform: translateY(-24px);
}

.midPane-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navigation-container {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.table-header {
  width: 100%;
  display: flex;
  flex: 1 1;
}

.table-header p {
  font-size: 14px;
  color: #acacac;
  text-align: left;
  margin-left: 1rem;
}

.table-header p:nth-child(1) {
  flex: 0.7 1;
}

.table-header p:nth-child(2) {
  flex: 0.15 1;
}

.table-header p:nth-child(3) {
  flex: 0.15 1;
}

.middlePane.dark-theme {
  background: #121212;
}

.middlePane.dark-theme .navigation-container h2 {
  color: #fff;
}

.middlePane.dark-theme .middlePane_cards {
  box-shadow: 0px 10px 60px rgba(1, 36, 158, 0.5);
}

.requestFiles {
  position: relative;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  align-content: center;
  align-items: center;
  justify-content: center;
}

/* .rainbow_shadow {
  position: relative;
  top: 0;
  left: 0;
   align-content: center; 
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 69px;
  height: 103px;
} 
*/
/* .user_avatar {
  position: relative;
  display: flex;
  top: 0;
  left: 0;
  align-content: center;
  align-items: center;
  justify-content: center;
} */

.requestFilesMascot1 {
  position: relative;
  top: 0;
  left: 150px; /* align-content: center; */
  align-content: center;
  align-items: center;
  justify-content: center;
}
.requestFilesMascot2 {
  position: relative;
  top: 0;
  left: -150px; /* align-content: center; */
  align-content: center;
  align-items: center;
  justify-content: center;
}
.requestFiles_content {
  align-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  display: flex;
  width: 100%;
  /* height: 100%; */
}

.requestFiles h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: -0.01em;

  /* sarvvidblack */

  color: #000000;
}

.requestFiles p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.01em;

  /* sarvvidgrey */

  color: #999999;
}

.requestFiles_btn {
  width: 30%;
  background: #000;
  border: none;
  outline: none;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  font-size: 0.8rem;
  font-weight: normal;
  font-family: "Poppins";
  border-radius: 10px;
  cursor: pointer;
  text-transform: none;
  margin: 0;
  padding: 1.2rem 1rem;
  margin-top: 2rem;
  transition: all 0.3s ease-in-out;
}

.button_depth {
  padding: 0.8rem;
  background: #fafafa;
  border: 1px solid #fafafa;
  box-sizing: border-box;
  box-shadow: 0px 100px 80px rgba(41, 72, 152, 0.05),
    0px 64.8148px 46.8519px rgba(41, 72, 152, 0.037963),
    0px 38.5185px 25.4815px rgba(41, 72, 152, 0.0303704),
    0px 20px 13px rgba(41, 72, 152, 0.025),
    0px 8.14815px 6.51852px rgba(41, 72, 152, 0.0196296),
    0px 1.85185px 3.14815px rgba(41, 72, 152, 0.012037);
  border-radius: 10px;
}

.navigation-subcontainer {
  width: 100%;
  display: flex;
  margin: 1rem 0;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.preference_title {
  margin: 1rem auto;
}

.user_details_settings {
  text-align: left;
  font-size: 0.8rem;
  transition: all 300ms ease-in-out;
}

.middlePane.dark-theme .preference_title {
  color: #fff;
}

.middlePane.dark-theme .midPane-header_settings h3 {
  color: #fff;
}

.settings-container {
  border-radius: 30px;
  margin-top: 5rem;
  box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5);
  padding: 1rem;
}

.settings-container.dark {
  box-shadow: 0px 10px 60px rgba(1, 36, 158, 0.5);
}

.settings-container.dark .user_storage_data {
  color: #acacac;
}

.midPane-header_settings {
  width: 100%;
  border-bottom: 1px solid #afafaf2a;
  text-align: left;
}

.settings_sec {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.middlePane_cards_settings {
  width: 100%;
  transition: all 300ms ease-in-out;
}

.user_info_settings {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 2.5rem 0.5rem;
}

/* meida */

@media (min-width: 1000px) {
  .middlePane {
    padding-left: 344px;
    padding-top: 30px;
    padding-bottom: 55px;
    padding-right: 39px;
  }

  .min_upload_section {
    display: none;
  }
}

@media (max-width: 1000px) {
  .middlePane {
    width: 100%;
    padding: 0 1rem;
  }

  .theme-toggle {
    display: none;
  }

  .middlePane_searchBar {
    width: 100%;
  }

  .midPane-header {
    width: 100%;
  }
  .midPane-header h2 {
    font-size: 1.2rem;
  }

  .middlePane_cards {
    padding: 0.7rem;
    border-radius: 10px;
  }

  .table-header p:nth-child(2) {
    flex: 0.15 1;
    text-align: right;
  }

  .table-header p:nth-child(3) {
    flex: 0.15 1;
    text-align: right;
  }

  .min_upload_section {
    margin: 1rem 0;
  }
}

.rightPane {
  width: 20%;
  height: 100%;
  /* border: 1px solid white; */
  /* background-color: #343951; */
  background-color: #fff;
  /* background-color: black; */
  /* color: #d0d6db; */
  color: black;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 1%;
  padding-bottom: 1%;
  cursor: default;
  overflow: visible;

  transition: all 300ms ease-in-out;
}
.rightPane_user {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
}

.rightPane_menu {
  width: 304px;
  height: 830px;
  left: 1107px;
  top: 206px;
  background: #ffffff;
  box-shadow: 0px 10px 60px rgb(226 236 249 / 50%);
  border-radius: 30px;
  padding: 25px;
  margin: 0 0 0 0;
}

/* .bell_icon:hover {
  background-color: white;
  color: #343951;
}
.settings_icon:hover {
  background-color: white;
  color: #343951;
} */
.user_circle {
  position: relative;
  top: 16%;
  /* margin-right: 0.5%; */
}

.user_info {
  display: flex;
  align-items: center;
}

.user_avatar {
  position: relative;
  display: flex;
  top: 0;
  left: 0;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.rainbow_shadow {
  position: relative;
  top: 0;
  left: 0;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 69px;
  height: 103px;
}

.user_img {
  position: absolute;
  /* top: 30px; */
  /* left: 30px; */
  width: 35px;
  height: 35px;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.user_details {
  margin-left: 1.2rem;
  text-align: left;
}

.user_details h3 {
  margin: 0;
  padding: 0;
  font-size: 1.3rem;
}

.user_details h6 {
  margin-top: 0.2rem;

  font-size: 1rem;
  /* background-color: radial-gradient(99.45% 314.32% at 0.55% 1.06%, #70DEAC 0%, #11ACFF 100%); */
  font-weight: normal;
  color: rgb(77, 77, 77);
}

/* .user_logoutDiv{

} */
/* .logout_text{

} */
.user_logout {
  position: relative;
  top: 16%;
}
.user_logoutDiv:hover {
  /* position: relative; */
  background-color: #005288;
  /* color: #343951; */
  color: white;
  /* box-shadow: 5px 5px 5px 5px solid #0f0; */
  padding: 1%;
  /* padding-right: 3%; */
  border-radius: 10px;
}

.storage_detail_header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.storage_detail_header h4 {
  margin: 0;
}

.storage_detail_header h4 {
  color: #acacac;
}

.storage_detail_header h3 {
  font-size: 1rem;
  color: #acacac;
}

.storage_detail_heading {
  text-align: left;
  margin: 0;
}
.storage_total {
  /* position: absolute;
  top: 28.5%; */
  font-size: 24px;
  /* right: 15.5%; */
  text-align: center;
  opacity: 0.8;
  z-index: 2;
  position: relative;
  top: -130px;
  left: 1%;
}
.storage_detail_desc {
  z-index: 2;
  font-size: 14px;
  text-align: center;
  opacity: 0.8;
  position: relative;
  bottom: 25px;
}

.storage_button {
  width: 100%;
  background: #000;
  border: none;
  outline: none;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  font-size: 1.2rem;
  font-weight: light;
  border-radius: 10px;
  cursor: pointer;
  text-transform: none;
  margin: 0;
  padding: 1.2rem 1rem;
  transition: all 0.3s ease-in-out;
}

.storage_btn_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.storage_btn_section p {
  font-size: 0.9rem;
  text-align: left;
  color: #acacac;
  margin: 0;
  margin-left: 1rem;
}

.storage_button:hover {
  background-color: #fff;
  color: #000;
  outline: 1px solid #000;
}

.storage_button:focus {
  outline: none;
}

.storage_used {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.upgrade_modal {
  overflow: scroll;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-radius: 2%; */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.modal_inner {
  width: 90%;
  height: 80%;
  padding: 2rem;
  border-radius: 25px;
  background: #fff;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.modal_inner::-webkit-scrollbar {
  display: none;
}

.upgrade_modal::-webkit-scrollbar {
  display: none;
}

.div_upgrade_heading {
  height: -webkit-min-content;
  height: min-content;
  cursor: default;
}
.upgradeStorageHeading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 33px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #000000;
  /* color: #3c4043; */
}
.upgrade_plans_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.upgrade_plans_div2 {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.upgrade_plans_div::-webkit-scrollbar {
  display: none;
}
.upgrade_plan_div {
  width: 20%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: rgb(233, 233, 233);
  box-shadow: 0px 10px 60px rgb(226 236 249 / 50%);
  border-radius: 30px;
}
/* .upgrade_plan_div:hover {
  box-shadow: 0 1px 10px rgb(0 195 255 / 30%);
  box-shadow: 0 1px 10px rgb(5 227 149 / 30%);
} */
.upgrade_plan_top {
  width: 100%;
  text-align: center;
}

.upgrade_plan_top h2 {
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  margin: 1%;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: -0.01em;
  color: #000000;
}

.upgrade_plan_top h3 {
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
}

.upgrade_plan_mid {
  display: flex;

  /* background-color: linear-gradient(180deg, #00ffd0 100%, rgba(255, 255, 255, 0) 100%); */
}

.upgrade_plan_mid1 {
  width: 10rem;
  height: 10rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 500px;
}

.upgrade_plan_mid2 {
  width: 10rem;
  height: 10rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #adf1e5 10%, #ffffff00 50%);
  border-radius: 500px;
}

.upgrade_plan_mid3 {
  width: 11rem;
  height: 11rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #e127ff 10%, #ffffff00 50%);
  border-radius: 500px;
}

.upgrade_plan_mid4 {
  width: 11rem;
  height: 11rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #00ff38 25%, #00a3ff 100%);
  border-radius: 500px;
}

.upgrade_plan_mid_text {
  background-color: #fff;
  height: 8rem;
  width: 8rem;
  border-radius: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upgrade_plan_mid img {
  position: relative;
  top: 0;
  left: 0;
  /* align-content: center; */
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* height: 103px; */
}

.upgrade_plan_recommendation {
  font-size: 120%;
}
.upgrade_plan_storage {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 1.7rem;
  margin-bottom: 0;
}

.upgrade_plan_button {
  background: none;
  outline: none;
  border: 1px solid #dadce0;
  margin: auto;
  padding-top: 2%;
  padding-bottom: 2%;
  border-radius: 4px;
  font-size: 120%;
  transition-duration: 0.3s;
  /* letter-spacing: 0.0107142857em; */
  letter-spacing: 0.02em;
  color: #005288;
}
.upgrade_plan_button:hover {
  cursor: pointer;
  background: #fff;
  color: #000;
  outline: 2px solid #000;

  /* color: white; */
}
.upgrade_plan_button:disabled {
  pointer-events: none;
  cursor: default;
  color: #3c4043;
}
.upgrade_plan_button:focus {
  outline: none;
}
.upgrade_plan_bottom {
  height: 36.5%;
  text-align: left;
  /* display: flex;
  flex-direction: column;
  justify-content: space-evenly; */
}

.upgrade_plan_bottom p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.01em;
  text-align: center;

  /* sarvvidgrey */

  color: #999999;
}

.upgrade_plan_bottom span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11.8824px;
  line-height: 13px;
  /* or 110% */

  letter-spacing: -0.01em;

  /* sarvvidblack */

  color: #000000;
}

.upgrade_plan_description {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-bottom: 5%;
  /* justify-content: space-evenly; */
}
.upgrade_plan_tick {
  margin-left: 8%;
  margin-right: 4%;
  color: #05e395;
}
.upgrade_plan_info {
  color: #3c4043;
}

.upgrade_plan_cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 100%;
}

.upgrade_plan_cta button {
  background: #000;
  border: none;
  outline: none;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  font-size: 0.8rem;
  font-weight: normal;
  font-family: "Poppins";
  border-radius: 10px;
  cursor: pointer;
  text-transform: none;

  padding: 1.2rem 1rem;
  transition: all 0.3s ease-in-out;
}
.upgrade_plan_cta p {
  font-family: "Poppins";
  margin: 3px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #999999;
}

/* new */

.file_details_section {
  margin-top: 3rem;
}

.file_detail {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.file_detail_name {
  display: flex;
  align-items: center;
}

.file_details {
  text-align: left;
  margin-left: 1rem;
}

.file_details_section h4,
.file_details_section p {
  margin: 0;
}

.file_details_section h4 {
  font-size: 16px;
  transition: all 300ms ease-in-out;
}

.file_details_section.dark h4 {
  color: #fff;
}

.file_details p {
  font-size: 14px;
  color: #acacac;
  margin-top: 0.2rem;
  height: auto;
  font-weight: bold;
  transition: all 300ms ease-in-out;
}

.file_detail_size {
  height: auto;
  color: #000;
  transition: all 300ms ease-in-out;
}

.file_detail_size {
  height: auto;
  color: #acacac;
}

.file_icon {
  padding: 0.8rem;
  background: #fafafa;
  border: 1px solid #fafafa;
  box-sizing: border-box;
  box-shadow: 0px 100px 80px rgba(41, 72, 152, 0.05),
    0px 64.8148px 46.8519px rgba(41, 72, 152, 0.037963),
    0px 38.5185px 25.4815px rgba(41, 72, 152, 0.0303704),
    0px 20px 13px rgba(41, 72, 152, 0.025),
    0px 8.14815px 6.51852px rgba(41, 72, 152, 0.0196296),
    0px 1.85185px 3.14815px rgba(41, 72, 152, 0.012037);
  border-radius: 10px;
  transition: all 300ms ease-in-out;
}

.file_icon.dark {
  background: #121212;
  border: 1px solid #121212;
  box-sizing: border-box;
  box-shadow: 0px 100px 80px rgba(41, 72, 152, 0.05),
    0px 64.8148px 46.8519px rgba(41, 72, 152, 0.037963),
    0px 38.5185px 25.4815px rgba(41, 72, 152, 0.0303704),
    0px 20px 13px rgba(41, 72, 152, 0.025),
    0px 8.14815px 6.51852px rgba(41, 72, 152, 0.0196296),
    0px 1.85185px 3.14815px rgba(41, 72, 152, 0.012037);
}

.second_circle,
.first_circle {
  -webkit-transform: translate3d(5rem, 5rem, 0);
          transform: translate3d(5rem, 5rem, 0);
}

.chart_svg {
  display: flex;
  align-items: center;
  justify-content: center;
}

.storage_chart {
  position: relative;
}

.storage_chart_details {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-70%, -50%, 0);
          transform: translate3d(-70%, -50%, 0);
}

.storage_chart_details h2,
.storage_chart_details p {
  margin: 0;
}

.storage_detail {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom_modal {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom_modal_inner {
  width: 50%;
  background: #fff;
  box-shadow: 0px 10px 60px rgb(226 236 249 / 50%);
  border-radius: 15px;
  padding: 1.5rem;
}

.custom_input {
  margin-bottom: 1.5rem;
}

.custom_input h4 {
  font-size: 1rem;
}

.custom_input input {
  width: 100%;
  border-radius: 5px;
  border: none;
  background: #adf1e5;
  padding: 0.3rem;
}

.custom_input input:focus {
  outline-color: #549c8f;
  outline-width: 1px;
}

.custom_input textarea {
  width: 100%;
  border-radius: 5px;
  border: none;
  background: #adf1e5;
  padding: 0.3rem;
}

.custom_input textarea:focus {
  outline-color: #549c8f;
  outline-width: 1px;
}

.upgrade_plan_price {
  text-align: center;
}

/* Media */
@media (max-width: 1000px) {
  .rightPane {
    display: none;
  }
}

@media (max-width: 1200px) {
  .upgrade_plans_div {
    flex-direction: column;
  }

  .upgrade_plan_div {
    width: 50%;
    margin: 2rem 0;
  }
}

@media (max-width: 768px) {
  .upgrade_plan_div {
    width: 75%;
    margin: 2rem 0;
  }
}

.CheckOnlineModal {
  overflow: scroll;
  /* border-radius: 2%; */
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* z-index: 4003; */
}
.CheckOnlineModal::-webkit-scrollbar {
  display: none;
}
.div_CheckOnline_heading {
  height: -webkit-min-content;
  height: min-content;
  margin-bottom: 2%;
  cursor: default;
}
.CheckOnlineHeading {
  color: #00b3ff;
  /* color: #3c4043; */
}
.CheckOnlineBody {
  text-align: left;
  color: #3c4043;
  letter-spacing: 0.02em;
}
.CheckOnlineInstruction {
  font-size: 135%;
  margin-bottom: 4%;
}
.CheckOnline_button {
  background: #00b3ff;
  outline: none;
  margin-top: 2%;
  border: 1px solid #dadce0;
  width: 20%;
  padding-top: 1%;
  padding-bottom: 1%;
  border-radius: 4px;
  font-size: 120%;
  transition-duration: 0.3s;
  /* letter-spacing: 0.0107142857em; */
  letter-spacing: 0.02em;
  color: white;
  position: absolute;
  right: 4%;
}
.CheckOnline_button:hover {
  cursor: pointer;
  /* background-color: #f6fafe; */
  /* color: white; */
}
/* .upgrade_plan_button:disabled {
  pointer-events: none;
  cursor: default;
  color: #3c4043;
} */
.CheckOnline_button:focus {
  outline: none;
}
.dinoGif {
  position: relative;
  width: 70%;
  left: 14%;
}

