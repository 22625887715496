.rightPane {
  width: 20%;
  height: 100%;
  /* border: 1px solid white; */
  /* background-color: #343951; */
  background-color: #fff;
  /* background-color: black; */
  /* color: #d0d6db; */
  color: black;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 1%;
  padding-bottom: 1%;
  cursor: default;
  overflow: visible;

  transition: all 300ms ease-in-out;
}
.rightPane_user {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
}

.rightPane_menu {
  width: 304px;
  height: 830px;
  left: 1107px;
  top: 206px;
  background: #ffffff;
  box-shadow: 0px 10px 60px rgb(226 236 249 / 50%);
  border-radius: 30px;
  padding: 25px;
  margin: 0 0 0 0;
}

/* .bell_icon:hover {
  background-color: white;
  color: #343951;
}
.settings_icon:hover {
  background-color: white;
  color: #343951;
} */
.user_circle {
  position: relative;
  top: 16%;
  /* margin-right: 0.5%; */
}

.user_info {
  display: flex;
  align-items: center;
}

.user_avatar {
  position: relative;
  display: flex;
  top: 0;
  left: 0;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.rainbow_shadow {
  position: relative;
  top: 0;
  left: 0;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 69px;
  height: 103px;
}

.user_img {
  position: absolute;
  /* top: 30px; */
  /* left: 30px; */
  width: 35px;
  height: 35px;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.user_details {
  margin-left: 1.2rem;
  text-align: left;
}

.user_details h3 {
  margin: 0;
  padding: 0;
  font-size: 1.3rem;
}

.user_details h6 {
  margin-top: 0.2rem;

  font-size: 1rem;
  /* background-color: radial-gradient(99.45% 314.32% at 0.55% 1.06%, #70DEAC 0%, #11ACFF 100%); */
  font-weight: normal;
  color: rgb(77, 77, 77);
}

/* .user_logoutDiv{

} */
/* .logout_text{

} */
.user_logout {
  position: relative;
  top: 16%;
}
.user_logoutDiv:hover {
  /* position: relative; */
  background-color: #005288;
  /* color: #343951; */
  color: white;
  /* box-shadow: 5px 5px 5px 5px solid #0f0; */
  padding: 1%;
  /* padding-right: 3%; */
  border-radius: 10px;
}

.storage_detail_header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.storage_detail_header h4 {
  margin: 0;
}

.storage_detail_header h4 {
  color: #acacac;
}

.storage_detail_header h3 {
  font-size: 1rem;
  color: #acacac;
}

.storage_detail_heading {
  text-align: left;
  margin: 0;
}
.storage_total {
  /* position: absolute;
  top: 28.5%; */
  font-size: 24px;
  /* right: 15.5%; */
  text-align: center;
  opacity: 0.8;
  z-index: 2;
  position: relative;
  top: -130px;
  left: 1%;
}
.storage_detail_desc {
  z-index: 2;
  font-size: 14px;
  text-align: center;
  opacity: 0.8;
  position: relative;
  bottom: 25px;
}

.storage_button {
  width: 100%;
  background: #000;
  border: none;
  outline: none;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  font-size: 1.2rem;
  font-weight: light;
  border-radius: 10px;
  cursor: pointer;
  text-transform: none;
  margin: 0;
  padding: 1.2rem 1rem;
  transition: all 0.3s ease-in-out;
}

.storage_btn_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.storage_btn_section p {
  font-size: 0.9rem;
  text-align: left;
  color: #acacac;
  margin: 0;
  margin-left: 1rem;
}

.storage_button:hover {
  background-color: #fff;
  color: #000;
  outline: 1px solid #000;
}

.storage_button:focus {
  outline: none;
}

.storage_used {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.upgrade_modal {
  overflow: scroll;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-radius: 2%; */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.modal_inner {
  width: 90%;
  height: 80%;
  padding: 2rem;
  border-radius: 25px;
  background: #fff;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.modal_inner::-webkit-scrollbar {
  display: none;
}

.upgrade_modal::-webkit-scrollbar {
  display: none;
}

.div_upgrade_heading {
  height: min-content;
  cursor: default;
}
.upgradeStorageHeading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 33px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #000000;
  /* color: #3c4043; */
}
.upgrade_plans_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.upgrade_plans_div2 {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.upgrade_plans_div::-webkit-scrollbar {
  display: none;
}
.upgrade_plan_div {
  width: 20%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: rgb(233, 233, 233);
  box-shadow: 0px 10px 60px rgb(226 236 249 / 50%);
  border-radius: 30px;
}
/* .upgrade_plan_div:hover {
  box-shadow: 0 1px 10px rgb(0 195 255 / 30%);
  box-shadow: 0 1px 10px rgb(5 227 149 / 30%);
} */
.upgrade_plan_top {
  width: 100%;
  text-align: center;
}

.upgrade_plan_top h2 {
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  margin: 1%;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: -0.01em;
  color: #000000;
}

.upgrade_plan_top h3 {
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
}

.upgrade_plan_mid {
  display: flex;

  /* background-color: linear-gradient(180deg, #00ffd0 100%, rgba(255, 255, 255, 0) 100%); */
}

.upgrade_plan_mid1 {
  width: 10rem;
  height: 10rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 500px;
}

.upgrade_plan_mid2 {
  width: 10rem;
  height: 10rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #adf1e5 10%, #ffffff00 50%);
  border-radius: 500px;
}

.upgrade_plan_mid3 {
  width: 11rem;
  height: 11rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #e127ff 10%, #ffffff00 50%);
  border-radius: 500px;
}

.upgrade_plan_mid4 {
  width: 11rem;
  height: 11rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #00ff38 25%, #00a3ff 100%);
  border-radius: 500px;
}

.upgrade_plan_mid_text {
  background-color: #fff;
  height: 8rem;
  width: 8rem;
  border-radius: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upgrade_plan_mid img {
  position: relative;
  top: 0;
  left: 0;
  /* align-content: center; */
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* height: 103px; */
}

.upgrade_plan_recommendation {
  font-size: 120%;
}
.upgrade_plan_storage {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 1.7rem;
  margin-bottom: 0;
}

.upgrade_plan_button {
  background: none;
  outline: none;
  border: 1px solid #dadce0;
  margin: auto;
  padding-top: 2%;
  padding-bottom: 2%;
  border-radius: 4px;
  font-size: 120%;
  transition-duration: 0.3s;
  /* letter-spacing: 0.0107142857em; */
  letter-spacing: 0.02em;
  color: #005288;
}
.upgrade_plan_button:hover {
  cursor: pointer;
  background: #fff;
  color: #000;
  outline: 2px solid #000;

  /* color: white; */
}
.upgrade_plan_button:disabled {
  pointer-events: none;
  cursor: default;
  color: #3c4043;
}
.upgrade_plan_button:focus {
  outline: none;
}
.upgrade_plan_bottom {
  height: 36.5%;
  text-align: left;
  /* display: flex;
  flex-direction: column;
  justify-content: space-evenly; */
}

.upgrade_plan_bottom p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.01em;
  text-align: center;

  /* sarvvidgrey */

  color: #999999;
}

.upgrade_plan_bottom span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11.8824px;
  line-height: 13px;
  /* or 110% */

  letter-spacing: -0.01em;

  /* sarvvidblack */

  color: #000000;
}

.upgrade_plan_description {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-bottom: 5%;
  /* justify-content: space-evenly; */
}
.upgrade_plan_tick {
  margin-left: 8%;
  margin-right: 4%;
  color: #05e395;
}
.upgrade_plan_info {
  color: #3c4043;
}

.upgrade_plan_cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 100%;
}

.upgrade_plan_cta button {
  background: #000;
  border: none;
  outline: none;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  font-size: 0.8rem;
  font-weight: normal;
  font-family: "Poppins";
  border-radius: 10px;
  cursor: pointer;
  text-transform: none;

  padding: 1.2rem 1rem;
  transition: all 0.3s ease-in-out;
}
.upgrade_plan_cta p {
  font-family: "Poppins";
  margin: 3px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #999999;
}

/* new */

.file_details_section {
  margin-top: 3rem;
}

.file_detail {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.file_detail_name {
  display: flex;
  align-items: center;
}

.file_details {
  text-align: left;
  margin-left: 1rem;
}

.file_details_section h4,
.file_details_section p {
  margin: 0;
}

.file_details_section h4 {
  font-size: 16px;
  transition: all 300ms ease-in-out;
}

.file_details_section.dark h4 {
  color: #fff;
}

.file_details p {
  font-size: 14px;
  color: #acacac;
  margin-top: 0.2rem;
  height: auto;
  font-weight: bold;
  transition: all 300ms ease-in-out;
}

.file_detail_size {
  height: auto;
  color: #000;
  transition: all 300ms ease-in-out;
}

.file_detail_size {
  height: auto;
  color: #acacac;
}

.file_icon {
  padding: 0.8rem;
  background: #fafafa;
  border: 1px solid #fafafa;
  box-sizing: border-box;
  box-shadow: 0px 100px 80px rgba(41, 72, 152, 0.05),
    0px 64.8148px 46.8519px rgba(41, 72, 152, 0.037963),
    0px 38.5185px 25.4815px rgba(41, 72, 152, 0.0303704),
    0px 20px 13px rgba(41, 72, 152, 0.025),
    0px 8.14815px 6.51852px rgba(41, 72, 152, 0.0196296),
    0px 1.85185px 3.14815px rgba(41, 72, 152, 0.012037);
  border-radius: 10px;
  transition: all 300ms ease-in-out;
}

.file_icon.dark {
  background: #121212;
  border: 1px solid #121212;
  box-sizing: border-box;
  box-shadow: 0px 100px 80px rgba(41, 72, 152, 0.05),
    0px 64.8148px 46.8519px rgba(41, 72, 152, 0.037963),
    0px 38.5185px 25.4815px rgba(41, 72, 152, 0.0303704),
    0px 20px 13px rgba(41, 72, 152, 0.025),
    0px 8.14815px 6.51852px rgba(41, 72, 152, 0.0196296),
    0px 1.85185px 3.14815px rgba(41, 72, 152, 0.012037);
}

.second_circle,
.first_circle {
  transform: translate3d(5rem, 5rem, 0);
}

.chart_svg {
  display: flex;
  align-items: center;
  justify-content: center;
}

.storage_chart {
  position: relative;
}

.storage_chart_details {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-70%, -50%, 0);
}

.storage_chart_details h2,
.storage_chart_details p {
  margin: 0;
}

.storage_detail {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom_modal {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom_modal_inner {
  width: 50%;
  background: #fff;
  box-shadow: 0px 10px 60px rgb(226 236 249 / 50%);
  border-radius: 15px;
  padding: 1.5rem;
}

.custom_input {
  margin-bottom: 1.5rem;
}

.custom_input h4 {
  font-size: 1rem;
}

.custom_input input {
  width: 100%;
  border-radius: 5px;
  border: none;
  background: #adf1e5;
  padding: 0.3rem;
}

.custom_input input:focus {
  outline-color: #549c8f;
  outline-width: 1px;
}

.custom_input textarea {
  width: 100%;
  border-radius: 5px;
  border: none;
  background: #adf1e5;
  padding: 0.3rem;
}

.custom_input textarea:focus {
  outline-color: #549c8f;
  outline-width: 1px;
}

.upgrade_plan_price {
  text-align: center;
}

/* Media */
@media (max-width: 1000px) {
  .rightPane {
    display: none;
  }
}

@media (max-width: 1200px) {
  .upgrade_plans_div {
    flex-direction: column;
  }

  .upgrade_plan_div {
    width: 50%;
    margin: 2rem 0;
  }
}

@media (max-width: 768px) {
  .upgrade_plan_div {
    width: 75%;
    margin: 2rem 0;
  }
}
