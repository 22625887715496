.middlePane {
  background-color: #fafafa;
  /* background-color: var(--secondary-color); */
  width: 80%;

  height: 100%;
  /* border: 1px solid red; */
  /* font-size: ; */
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;

  transition: all 300ms ease-in-out;
}

.middlePane_searchBar {
  width: 90%;
  transition: all 300ms ease-in-out;
}

.middlePane_searchBar.dark-theme .searchBar_text {
  background: #0d0d0d;
}

.middlePane_upper {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.middlePane::-webkit-scrollbar {
  display: none;
}

.searchBar_text {
  width: 100% !important;
  /* background-color: green; */
  border-radius: 9px;
  outline: none;
  border: none;
  background-color: #fff;
  padding: 0.4rem 0.5rem;
  padding-left: 2rem;
  transition: all 300ms ease-in-out;
}

.searchBar_text.opened {
  padding-left: 1.5rem;
}

.searchBar_text.dark {
  color: #fff;
}

.middlePane_cards_recycle {
  /* height: 30%; */
  margin-top: 4%;
  transition: all 300ms ease;
  background: rgb(255, 255, 255);
  box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5);
  border-radius: 30px;
  padding: 2rem;
  transition: all 300ms ease-in-out;

  /* margin-bottom: 1%; */
  /* border: 1px solid red; */
}

.recent_heading {
  padding-right: 2%;
  padding-left: 2%;
  color: #005288;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: default;
}

.recent_content {
  padding: 2%;
  color: #005288;
  cursor: default;
}
.file_content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.95%;
  border-bottom: 1px solid #005288;
  margin-bottom: 2%;
}
.file_content:hover {
  box-shadow: 0 0 20px rgb(0, 195, 255);
}

.close_button {
  display: none;
  opacity: 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.close_button.active_close {
  display: block;
  opacity: 1;
}

/* Theme button */

.theme-toggle {
  height: 24px;
  overflow: hidden;
}

.theme-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 300ms ease-in-out;
}

.theme-toggle.dark .theme-btn {
  transform: translateY(-24px);
}

.midPane-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navigation-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.table-header {
  width: 100%;
  display: flex;
  flex: 1;
}

.table-header p {
  font-size: 14px;
  color: #acacac;
  text-align: left;
  margin-left: 1rem;
}

.table-header p:nth-child(1) {
  flex: 0.7;
}

.table-header p:nth-child(2) {
  flex: 0.15;
}

.table-header p:nth-child(3) {
  flex: 0.15;
}

.middlePane.dark-theme {
  background: #121212;
}

.middlePane.dark-theme .navigation-container h2 {
  color: #fff;
}

.middlePane.dark-theme .middlePane_cards {
  box-shadow: 0px 10px 60px rgba(1, 36, 158, 0.5);
}

.empty_files_shared p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin: 1rem 0;
  text-align: center;
  letter-spacing: -0.01em;

  /* sarvvidgrey */

  color: #999999;
}

.empty_files_recycle p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin: 1rem 0;
  text-align: center;
  letter-spacing: -0.01em;

  /* sarvvidgrey */

  color: #999999;
}

.empty_files_shared_btn {
  width: 30%;
  background: #000;
  border: none;
  outline: none;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  font-size: 0.8rem;
  font-weight: normal;
  font-family: "Poppins";
  border-radius: 10px;
  cursor: pointer;
  text-transform: none;
  margin: 0;
  padding: 1.2rem 1rem;
  transition: all 0.3s ease-in-out;
}

.button_depth {
  padding: 0.8rem;
  background: #fafafa;
  border: 1px solid #fafafa;
  box-sizing: border-box;
  box-shadow: 0px 100px 80px rgba(41, 72, 152, 0.05),
    0px 64.8148px 46.8519px rgba(41, 72, 152, 0.037963),
    0px 38.5185px 25.4815px rgba(41, 72, 152, 0.0303704),
    0px 20px 13px rgba(41, 72, 152, 0.025),
    0px 8.14815px 6.51852px rgba(41, 72, 152, 0.0196296),
    0px 1.85185px 3.14815px rgba(41, 72, 152, 0.012037);
  border-radius: 10px;
}

.navigation-subcontainer {
  width: 100%;
  display: flex;
  margin: 1rem 0;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.middlePane_cards_shared {
  border-radius: 30px;
  margin-top: 5rem;
  box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5);
}

.middlePane_cards_shared.dark {
  box-shadow: 0px 10px 60px rgba(1, 36, 158, 0.5);
}

.empty_files_shared img {
  width: 80%;
  max-width: 500px;
}

.empty_files_recycle img {
  width: 80%;
  max-width: 300px;
}

.empty_files_shared {
  padding: 1rem;
}

.empty_files_recycle {
  border-radius: 30px;
  margin-top: 5rem;
  box-shadow: 0px 10px 60px rgba(226, 236, 249, 0.5);
  padding: 1rem;
}

.empty_files_recycle.dark {
  box-shadow: 0px 10px 60px rgba(1, 36, 158, 0.5);
}

/* meida */

@media (min-width: 1000px) {
  .middlePane {
    padding-left: 344px;
    padding-top: 30px;
    padding-bottom: 55px;
    padding-right: 39px;
  }

  .min_upload_section {
    display: none;
  }
}

@media (max-width: 1000px) {
  .middlePane {
    width: 100%;
    padding: 0 1rem;
  }

  .theme-toggle {
    display: none;
  }

  .middlePane_searchBar {
    width: 100%;
  }

  .midPane-header {
    width: 100%;
  }
  .midPane-header h2 {
    font-size: 1.2rem;
  }

  .middlePane_cards {
    padding: 0.7rem;
    border-radius: 10px;
  }

  .table-header p:nth-child(2) {
    flex: 0.15;
    text-align: right;
  }

  .table-header p:nth-child(3) {
    flex: 0.15;
    text-align: right;
  }

  .min_upload_section {
    margin: 1rem 0;
  }
}
